import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import FunnelCarousel from "components/ui/extended/FunnelCarousel";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import { Integrations } from "components/ui/extended/Integrations";
import Customers from "components/ui/extended/Customers";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import MarketingAutomationAltFeatures from "components/ui/extended/MarketingAutomationAltFeatures";
import RegisterForMarketingAutomation from "components/ui/extended/RegisterForMarketingAutomation";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";

interface MarketingAutomationPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: MarketingAutomationPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.solutions.marketing-automation.seo.title")}
        description={t("pages.solutions.marketing-automation.seo.description")}
        image={{
          relativePath: "meta/best-marketing-automation-software.jpg",
          alt: "The best Marketing Automation Software",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.solutions.marketing-automation.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t(
              "pages.solutions.marketing-automation.seo.description"
            ),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.solutions.marketing-automation.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.solutions.marketing-automation.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeaderWithVideo
        title={t("pages.solutions.marketing-automation.title")}
        description={t("pages.solutions.marketing-automation.description")}
        video={{
          path: "pages/solutions/marketing-automation/funnel-creation.mov",
        }}
        titleMaxWidth={650}
        descriptionMaxWidth={550}
        ctaRegister={{ show: true }}
      />

      <FunnelCarousel
        title={t("pages.solutions.marketing-automation.funnel-carousel.title")}
        description={t(
          "pages.solutions.marketing-automation.funnel-carousel.description"
        )}
      />

      <ImageWithDescription
        title={t("pages.solutions.marketing-automation.automate-email.title")}
        description={t(
          "pages.solutions.marketing-automation.automate-email.description"
        )}
        cta={{
          link: "/features/marketing-funnel/",
          text: t("pages.solutions.marketing-automation.automate-email.cta"),
        }}
        image={{
          relativePath:
            "pages/solutions/marketing-automation/marketing-automation-followup.jpg",
          alt: t("pages.solutions.marketing-automation.automate-email.title"),
          fullWidth: true,
          position: "right",
        }}
      />

      <ImageWithDescription
        title={t(
          "pages.solutions.marketing-automation.engaged-customers.title"
        )}
        description={t(
          "pages.solutions.marketing-automation.engaged-customers.description"
        )}
        cta={{
          link: "/features/marketing-funnel/",
          text: t("pages.solutions.marketing-automation.engaged-customers.cta"),
        }}
        image={{
          relativePath:
            "pages/solutions/marketing-automation/marketing-automation-engaged-customers.jpg",
          alt: t(
            "pages.solutions.marketing-automation.engaged-customers.title"
          ),
          fullWidth: true,
          position: "left",
        }}
      />

      <ImageWithDescription
        title={t("pages.solutions.marketing-automation.onboard-users.title")}
        description={t(
          "pages.solutions.marketing-automation.onboard-users.description"
        )}
        cta={{
          link: "/features/marketing-funnel/",
          text: t("pages.solutions.marketing-automation.onboard-users.cta"),
        }}
        image={{
          relativePath:
            "pages/solutions/marketing-automation/marketing-automation-onboard-users.jpg",
          alt: t("pages.solutions.marketing-automation.onboard-users.title"),
          fullWidth: true,
          position: "right",
        }}
      />

      <MarketingAutomationAltFeatures location={location} />

      <ImageWithDescription
        title={t("pages.solutions.marketing-automation.segmentation.title")}
        description={t(
          "pages.solutions.marketing-automation.segmentation.description"
        )}
        cta={{
          link: "/features/audience-segmentation/",
          text: t("pages.solutions.marketing-automation.segmentation.cta"),
        }}
        image={{
          relativePath:
            "pages/solutions/marketing-automation/contact-segmentation-for-marketing-automation-filters.jpg",
          alt: t("pages.solutions.marketing-automation.segmentation.title"),
          fullWidth: true,
          position: "left",
        }}
      />

      <Integrations
        title={t("pages.solutions.marketing-automation.integrations.title")}
        description={t(
          "pages.solutions.marketing-automation.integrations.description"
        )}
      />

      <RegisterForMarketingAutomation />

      <Customers />

      <QuestionsAndAnswers
        title={t("pages.solutions.marketing-automation.QA.title")}
        description={t("pages.solutions.marketing-automation.QA.description")}
        elements={Array.from(Array(3).keys()).map((i) => {
          return {
            question: t(
              `pages.solutions.marketing-automation.QA.${i + 1}.question`
            ),
            answer: t(
              `pages.solutions.marketing-automation.QA.${i + 1}.answer`
            ),
          };
        })}
      />
    </GlobalWrapper>
  );
};
export const MarketingAutomationQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/best-marketing-automation-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
